:root {
  --main-background: #f4f4f4;
  --card-background: #fff;
  --title-main: #5B9BD5;
  --title-black: #000;
  --text-color: #666;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 100%;
  height: auto;
  padding: 50px;
  box-sizing: border-box;
  background-color: var(--main-background);
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  width: 90%;
  background-color: var(--card-background);
  border: 1px solid var(--main-background);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease-in-out;
}

.content:hover {
  transform: scale(1.02);
}

.image {
  width: 700px;
  height: 500px;
  border-radius: 8px;
  margin: 20px;
}

.content .text {
  flex: 1;
  padding: 0 30px;
}

.content .text h1 {
  font-size: 35px;
  color: #0c6cc5;
  max-width: 400px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.content .text p {
  font-size: 18px;
  line-height: 1.5;
  color: var(--text-color);
}

.counter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 3% auto;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.counter:hover {
  background-color: #e0e0e0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.count {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  padding: 1.5vw;
}

.count span {
  font-size: 5vw;
  font-weight: bold;
  color: #333;
  transition: color 0.3s ease, transform 0.3s ease;
}

.count span:hover {
  color: var(--title-main);
  transform: scale(1.1);
}

.count h4 {
  font-size: 1.2vw;
  margin-top: 1vw;
  color: var(--text-color);
}

@media (max-width: 1200px) {
  .content {
    flex-direction: column;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100vw;
    padding: 20px 10px;
    height: auto;
    box-sizing: border-box;
 
  }

  .image {
    width: 92%;
    height: 60%;
  }

  .content .text h1 {
    font-size: 35px;
    color: #0c6cc5;
    max-width: 400px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-top: -10px;
    margin-bottom: -10px;
  }
}