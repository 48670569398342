:root {
  --main-background: #f4f4f4;
  --card-background: #fff;
  --title-main: #5B9BD5;
  --title-black: #000;
  --text-color: #666;
}

.container {
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
  width: 100%;
  height: 130px;
  z-index: 2;
  background: transparent;
  color: var(--card-background);
}

.containerActive {
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
  width: 100%;
  height: 130px;
  z-index: 2;
  background-color: var(--card-background);
  color: var(--title-black);
}

.logo {
  width: 140px;
  height: 120px;
}

.menuLinks {
  display: flex;
  justify-self: end;
  margin-left: auto;
  margin-right: 100px;
  gap: 40px;
}

.menuLinkActive a {
  color: var(--title-black);
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
}

.menuLinkActive a:hover {
  color: var(--title-main);
}

.menuLink a {
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
}

.menuLink a:hover {
  color: var(--title-main);
}

.mobileMenuIcon {
  display: none;
}

.mobileMenuIcon img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.7); /* Daha koyu arka plan */
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
}

.modalContent {
  background-color: #5B9BD5;
  width: 250px;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.modalContainer {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.modalContainer > span {
  font-size: 16px;
  font-weight: 500;
}

.modalLink a {
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  padding: 10px 0;
  transition: color 0.3s;
}

.modalLink span:hover {
  color: var(--text-color);
}

.modalLink {
  width: 100%;
  border-bottom: 2px solid #ccc;
}

@media only screen and (max-width: 1080px) {
  .menuLinks {
    display: none;
  }

  .mobileMenuIcon {
    display: block;
    position: absolute;
    right: 40px;
    top: 20px;
    z-index: 3;
  }
}

@media only screen and (max-width: 900px) {
  .logo {
    width: 120px;
    height: 100px;
    margin-top: -25px;
    margin-left: -30px;
  }
}
