.footer {
  background-color: #2c3e50; 
  padding: 40px 20px;
  text-align: center;
  border-top: 2px solid #34495e;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
}

.section {
  flex: 1;
  margin: 10px;
}

.title {
  font-size: 24px;
  color: #ecf0f1;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  color: #bdc3c7; 
  margin-bottom: 8px;
}

.text {
  font-size: 16px;
  color: #bdc3c7;
  margin-bottom: 6px;
}

.link {
  color: #3498db; 
  text-decoration: none;
  margin: 0 5px;
}

.link:hover {
  text-decoration: underline;
}

.copyright {
  margin-top: 20px;
  font-size: 14px;
  color: #95a5a6; /* Daha hafif gri */
}


@media (max-width: 1000px) {
  .container {
    flex-direction: column;
  }

  .footerLogo,
  .footerLinks,
  .office,
  .contact {
    width: 100%;
    padding: 10px;
  }
}