:root {
    --main-background: #f4f4f4;
    --card-background: #fff;
    --title-main: #5B9BD5;
    --title-black: #000;
    --text-color: #666;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    height: auto;
    padding: 50px;
    box-sizing: border-box;
    background-color: var(--main-background);
}

@media only screen and (max-width: 1200px) {
    .content {
        flex-direction: column;
    }
}