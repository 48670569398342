.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
}

.backgroundVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(44, 187, 243, 0.5);
  z-index: -1;
}

.contentTitle {
  margin: 0;
  font-size: 3rem; 
  color: #464343; 
  font-weight: 400; 
  text-align: center; 
  font-family: 'Georgia', serif; 
  text-shadow: 1px 1px 2px rgb(235, 251, 251);
}

.first {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  color: #fff;
  margin-top: -140px;
  z-index: 1;
  overflow: hidden;
}

.first h1 {
  font-size: 3rem;
  max-width: 800px;
  font-weight: bold;
}

.first p {
  font-size: 1.5rem;
  margin-bottom: 30px;
  max-width: 600px;
}

.first button {
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
  background: none;
  border-width: 2px;
  border-style: solid;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100vw; }
}

@keyframes blinkCaret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}

.typing-container {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid black;
  font-size: 2em;
  animation: typing 4s steps(40, end), blinkCaret .75s step-end infinite;
}

.typing-container span {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media only screen and (max-width: 768px) {
  .first h2 {
    max-width: 500px;
    font-size: 20px;
    font-weight: bold;
  }

  .first button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
    background: none;
    border-width: 2px;
    border-style: solid;
  }

  .contentTitle {
    margin: 0;
    font-size: 24px;   
    color: #464343; 
    font-weight: 600; 
    text-align: center; 
    font-family: 'Georgia', serif; 
    text-shadow: 1px 1px 2px rgb(235, 251, 251);
  }
}