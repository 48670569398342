:root {
  --main-background: #f4f4f4;
  --card-background: #fff;
  --title-main: #5B9BD5;
  --title-black: #000;
  --text-color: #666;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
  padding: 50px;
  box-sizing: border-box;
  background-color: var(--main-background);
}

.title {
  margin-bottom: 30px;
}

.title h1 {
  font-size: 28px;
  color: #0c6cc5;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  background-color: var(--card-background);
  border: 1px solid var(--main-background);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease-in-out;
}

.inputBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.inputBox label {
  font-size: 16px;
  color: #555;
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
}

.field, .fieldMess {
  width: 100%;
  max-width: 500px;
  height: 50px;
  padding: 10px;
  border: 1px solid #0c6cc5;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.field:focus, .fieldMess:focus {
  border-color: red;
  outline: none;
}

.fieldMess {
  height: 200px;
  resize: none;
}

button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #0c6cc5;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #529dec;
}

.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 15px;
}

.checkbox input[type="checkbox"] {
  margin-right: 10px;
}

.checkbox label {
  font-size: 14px;
  color: #555;
}

.checkbox span {
  cursor: pointer;
  text-decoration: underline;
  color: #000;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal.open {
  opacity: 1;
  transform: scale(1);
}

.modalContent {
  width: 600px;
  height: 600px;
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  transform: translateY(-10%);
  transition: transform 0.3s ease;
  overflow-y: auto;
  margin-top: 100px;
  text-align: left;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: var(--text-color);
}