.iconContainer {
    position: fixed;
    bottom: 80px;
    left: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    z-index: 1000;
  }
  
  .icon {
    position: relative;
    font-size: 2rem;
    color: #fff;
    background-color: #25d366;
    padding: 10px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  
  .icon:nth-child(2) {
    background-color: #25d366;
  }
  
  .icon:nth-child(3) {
    background-color: #C13584; 
  }
  
  .icon::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3); 
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: wave 2s infinite;
  }
  
  .icon:hover::before {
    background-color: rgba(255, 255, 255, 0.5); /* Change on hover */
  }
  
  @keyframes wave {
    0% {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    50% {
      width: 150%;
      height: 150%;
      opacity: 0.5;
    }
    100% {
      width: 200%;
      height: 200%;
      opacity: 0;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .iconContainer {
      position: fixed;
      bottom: 100px;
      left: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      z-index: 1000;
    }

    .icon {
      position: relative;
      font-size: 1.5rem;
      color: #fff;
      background-color: #25d366;
      padding: 10px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  }