:root {
    --main-background: #f4f4f4;
    --card-background: #fff;
    --title-main: #5B9BD5;
    --title-black: #000;
    --text-color: #666;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    height: auto;
    padding: 50px;
    box-sizing: border-box;
    background-color: var(--main-background);
}

.title {
    justify-self: flex-start;
    font-size: 35px;
    color: #0c6cc5;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
    background-color: var(--card-background);
    border: 1px solid var(--main-background);
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;
    padding: 30px;
}

.content:hover {
    transform: scale(1.02);
}

.image {
    width: 40%;
    height: 35%;
    border-radius: 10px;
    margin-right: 20px;
}

.text {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.text span {
    max-width: 600px;
    font-size: 20px;
    line-height: 1.5;
    color: var(--text-color);
    margin-top: 20px;
}

@media only screen and (max-width: 1200px) {
    .content {
        flex-direction: column;
    }
    .text > div {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .text span {
        max-width: 600px;
        font-size: 16px;
        line-height: 1.5;
        color: var(--text-color);
        margin-top: -20px;
    }
    .title {
        justify-self: flex-start;
        font-size: 30px;
        color: #0c6cc5;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        margin-top: -10px;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        flex-direction: column;
    }
    .text > div {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .image {
        width: 96%;
        height: 35%;
        border-radius: 10px;
        margin-right: 20px;
    }
    .text span {
        max-width: 600px;
        font-size: 16px;
        line-height: 1.5;
        color: var(--text-color);
        margin-top: -20px;
    }
    .title {
        justify-self: flex-start;
        font-size: 28px;
        color: #0c6cc5;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        margin-top: -10px;
    }
}